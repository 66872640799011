import revive_payload_client_fvG3IRWBRHaYN1O3YF5eeXKFKQOONJWBXCI8BmtaorA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pqmqCKCTX_7IEEvhZLR4kd07j8iI_DPvUgUyzZ61a7E from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KjhdbiLmri6MG1Mn_z4g_t5JnDjyPlAo4tZeThVdlsI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_qdcxdlwbfw7h_lK6_Rv0_fN5nBe_eSPLe_ayerzy9x0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xDyRHyDPCWH02FAIN_fwg6gbTEpRwCf_dfsMYu1At84 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3LKPV_kyj0sYwM_yOjEAe15v5TNJsQ_mEcozuFXs3Jk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_N7JSi7jq_ldpej8p81mfHhJn__TBQLgdA7RqNIKWDIM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_K_x299lcL2HQLyneEB4R4OTVuH4SD3VTtiJyThroCRI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_iieHUJP83rz82LnOwnXFTO2vi9RmoA2wQ4AtmOjfJKY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_b6tMprELJsbXO6Z_6MBZKwZMQU4u__NLPm_iQWcQF4E from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_kjx4ZSnsAqtm1n6jnAqkbg9iXs_m1LGbcdO47LBkXRM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import errors_mcvDaFcsef7hipGTqDYWWedMZe_etm1AW8d8vhSqo_4 from "/opt/buildhome/repo/app/plugins/errors.ts";
import _0_routeRules_aRktwn18PtZcChfchq30dsWTAQGxYEEni1wLV71fvQ0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_9E1xUURGB25yImIBzi0l4J57EjFq29E783HWgC0AOJQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_fvG3IRWBRHaYN1O3YF5eeXKFKQOONJWBXCI8BmtaorA,
  unhead_pqmqCKCTX_7IEEvhZLR4kd07j8iI_DPvUgUyzZ61a7E,
  router_KjhdbiLmri6MG1Mn_z4g_t5JnDjyPlAo4tZeThVdlsI,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_qdcxdlwbfw7h_lK6_Rv0_fN5nBe_eSPLe_ayerzy9x0,
  navigation_repaint_client_xDyRHyDPCWH02FAIN_fwg6gbTEpRwCf_dfsMYu1At84,
  check_outdated_build_client_3LKPV_kyj0sYwM_yOjEAe15v5TNJsQ_mEcozuFXs3Jk,
  chunk_reload_client_N7JSi7jq_ldpej8p81mfHhJn__TBQLgdA7RqNIKWDIM,
  plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_K_x299lcL2HQLyneEB4R4OTVuH4SD3VTtiJyThroCRI,
  siteConfig_iieHUJP83rz82LnOwnXFTO2vi9RmoA2wQ4AtmOjfJKY,
  inferSeoMetaPlugin_b6tMprELJsbXO6Z_6MBZKwZMQU4u__NLPm_iQWcQF4E,
  titles_kjx4ZSnsAqtm1n6jnAqkbg9iXs_m1LGbcdO47LBkXRM,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0,
  errors_mcvDaFcsef7hipGTqDYWWedMZe_etm1AW8d8vhSqo_4,
  _0_routeRules_aRktwn18PtZcChfchq30dsWTAQGxYEEni1wLV71fvQ0,
  defaults_9E1xUURGB25yImIBzi0l4J57EjFq29E783HWgC0AOJQ
]