
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as storiesljJgBRn3jC2lJlFJbYQBryWU6nKaeWKWnffY8P8mM6oMeta } from "/opt/buildhome/repo/app/pages/about/stories.vue?macro=true";
import { default as _91tradeshow_936szJDlHz_45eC_fNiXcvZv0h74Ez1kNi5seD1fUVwu3bMMeta } from "/opt/buildhome/repo/app/pages/about/tradeshows/[tradeshow].vue?macro=true";
import { default as tradeshowsEKlqsfRzqmwg3wKY1nOd7MJJq3Y4C_nCUQy8j1UeGjQMeta } from "/opt/buildhome/repo/app/pages/about/tradeshows.vue?macro=true";
import { default as aboutQTGs1Tivaf63aUdG_vyL6eqqmZEovM3XmtcZkJgI5x8Meta } from "/opt/buildhome/repo/app/pages/about.vue?macro=true";
import { default as indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as rfqcT87NQCR7PpWA_POm7zpeXfcwZBJmamVrD2aT39KelIMeta } from "/opt/buildhome/repo/app/pages/contact/rfq.vue?macro=true";
import { default as contactmbeKG1_45LsNzD75CSpoyynnlwiezGt2pzSE37jXWEq_45kMeta } from "/opt/buildhome/repo/app/pages/contact.vue?macro=true";
import { default as _91_46_46_46404_938BaiHOkijOpM4Kqro9_45PJtMIx32pVBoE_45pk0o9I8yKUMeta } from "/opt/buildhome/repo/app/pages/[...404].vue?macro=true";
import { default as cable_45assembliesDBLmfRX_453Q_45j3SFXRPt1yprmkc42XdCYuwRytTWJtNgMeta } from "/opt/buildhome/repo/app/pages/services/cable-assemblies.vue?macro=true";
import { default as electromech_45assembliese_uunKG0S9AgOGT2Dc6GudseapBnXkYfMO9OdEpyBQcMeta } from "/opt/buildhome/repo/app/pages/services/electromech-assemblies.vue?macro=true";
import { default as servicesgteNbaGt7pGUYcVpmXzIsxpEgOtTOPJuERc6tnO5xPsMeta } from "/opt/buildhome/repo/app/pages/services.vue?macro=true";
import { default as literatureKop0GE_45jn9ygZ1jjfT7rHnMmQ_45359LM8YP6sEMunTdYMeta } from "/opt/buildhome/repo/app/pages/resources/literature.vue?macro=true";
import { default as certificationsypJUGbP0nVAz4c3WmVKXndebhWL9UDa6T1oIzLdE78gMeta } from "/opt/buildhome/repo/app/pages/resources/certifications.vue?macro=true";
import { default as resourcesHNCTQXeHjschmpzOgsUPSoNd_45PUJ_eqLsXWkEMAmbJwMeta } from "/opt/buildhome/repo/app/pages/resources.vue?macro=true";
import { default as terminationCTZMO_450PvAUPtBZu6d8seWCbSXcaY9lAPXr0KB_45qansMeta } from "/opt/buildhome/repo/app/pages/capabilities/termination.vue?macro=true";
import { default as wire_45splicingeiAtMi5BNAssciWGToUsfDiH7XF3orEhNOBriYqQSdUMeta } from "/opt/buildhome/repo/app/pages/capabilities/wire-splicing.vue?macro=true";
import { default as overmolded_45cablessyRt9B6DnUIYwq_stnHUHuM4icYK5HktMzjYRlq_45uR4Meta } from "/opt/buildhome/repo/app/pages/capabilities/overmolded-cables.vue?macro=true";
import { default as capabilitiesy0IWBUm6Qray77SbRvjMwlZrGo45xhjIYtt0kjhKJEkMeta } from "/opt/buildhome/repo/app/pages/capabilities.vue?macro=true";
import { default as indexnDu3O_2EAXNFsyl_UZdd8vEnD8UqCdCePf9feQnFB_AMeta } from "/opt/buildhome/repo/app/pages/industries/index.vue?macro=true";
import { default as _91industry_93f5kjNEgm_NvFCKSHMS4fOEITMcqqbti4S0_0vu_45z2ioMeta } from "/opt/buildhome/repo/app/pages/industries/[industry].vue?macro=true";
import { default as component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@9.23.0_jiti@2.4_0f8b8a096a28e1eb064d078ff7523eb2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutQTGs1Tivaf63aUdG_vyL6eqqmZEovM3XmtcZkJgI5x8Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about.vue"),
    children: [
  {
    name: "about-stories",
    path: "stories",
    meta: storiesljJgBRn3jC2lJlFJbYQBryWU6nKaeWKWnffY8P8mM6oMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/stories.vue")
  },
  {
    name: "about-tradeshows",
    path: "tradeshows",
    meta: tradeshowsEKlqsfRzqmwg3wKY1nOd7MJJq3Y4C_nCUQy8j1UeGjQMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/tradeshows.vue"),
    children: [
  {
    name: "about-tradeshows-tradeshow",
    path: ":tradeshow()",
    meta: _91tradeshow_936szJDlHz_45eC_fNiXcvZv0h74Ez1kNi5seD1fUVwu3bMMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/about/tradeshows/[tradeshow].vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactmbeKG1_45LsNzD75CSpoyynnlwiezGt2pzSE37jXWEq_45kMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/contact.vue"),
    children: [
  {
    name: "contact-rfq",
    path: "rfq",
    meta: rfqcT87NQCR7PpWA_POm7zpeXfcwZBJmamVrD2aT39KelIMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/contact/rfq.vue")
  }
]
  },
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/[...404].vue")
  },
  {
    name: "services",
    path: "/services",
    meta: servicesgteNbaGt7pGUYcVpmXzIsxpEgOtTOPJuERc6tnO5xPsMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services.vue"),
    children: [
  {
    name: "services-cable-assemblies",
    path: "cable-assemblies",
    meta: cable_45assembliesDBLmfRX_453Q_45j3SFXRPt1yprmkc42XdCYuwRytTWJtNgMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services/cable-assemblies.vue")
  },
  {
    name: "services-electromech-assemblies",
    path: "electromech-assemblies",
    meta: electromech_45assembliese_uunKG0S9AgOGT2Dc6GudseapBnXkYfMO9OdEpyBQcMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/services/electromech-assemblies.vue")
  }
]
  },
  {
    name: "resources",
    path: "/resources",
    meta: resourcesHNCTQXeHjschmpzOgsUPSoNd_45PUJ_eqLsXWkEMAmbJwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources.vue"),
    children: [
  {
    name: "resources-literature",
    path: "literature",
    meta: literatureKop0GE_45jn9ygZ1jjfT7rHnMmQ_45359LM8YP6sEMunTdYMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources/literature.vue")
  },
  {
    name: "resources-certifications",
    path: "certifications",
    meta: certificationsypJUGbP0nVAz4c3WmVKXndebhWL9UDa6T1oIzLdE78gMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/resources/certifications.vue")
  }
]
  },
  {
    name: "capabilities",
    path: "/capabilities",
    meta: capabilitiesy0IWBUm6Qray77SbRvjMwlZrGo45xhjIYtt0kjhKJEkMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities.vue"),
    children: [
  {
    name: "capabilities-termination",
    path: "termination",
    meta: terminationCTZMO_450PvAUPtBZu6d8seWCbSXcaY9lAPXr0KB_45qansMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/termination.vue")
  },
  {
    name: "capabilities-wire-splicing",
    path: "wire-splicing",
    meta: wire_45splicingeiAtMi5BNAssciWGToUsfDiH7XF3orEhNOBriYqQSdUMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/wire-splicing.vue")
  },
  {
    name: "capabilities-overmolded-cables",
    path: "overmolded-cables",
    meta: overmolded_45cablessyRt9B6DnUIYwq_stnHUHuM4icYK5HktMzjYRlq_45uR4Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/capabilities/overmolded-cables.vue")
  }
]
  },
  {
    name: "industries",
    path: "/industries",
    meta: indexnDu3O_2EAXNFsyl_UZdd8vEnD8UqCdCePf9feQnFB_AMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/industries/index.vue")
  },
  {
    name: "industries-industry",
    path: "/industries/:industry()",
    meta: _91industry_93f5kjNEgm_NvFCKSHMS4fOEITMcqqbti4S0_0vu_45z2ioMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/industries/[industry].vue")
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/cable-assemblies",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/electromech-assemblies",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/certifications",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/literature",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/stories",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/tradeshows",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/rfq",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/capabilities/overmolding",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/products",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/products/products",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/products-mobile",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/products-mobile/products",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/company/the-trexon-family",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/company-mobile/the-trexon-family",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  },
  {
    name: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_kMeta?.name,
    path: "/ics-jobs",
    component: component_45stubF_gRNwC7bCAdkty_459Ve73tbgObuEZXtoaIH68BG3X_k
  }
]