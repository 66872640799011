import { useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-LQZPC5MQTC"})
    const autopilot = useScript({"key":"autopilot","src":"//cdn.bc0a.com/autopilot/f00000000290627/autopilot_sdk.js"}, { use: () => ({ autopilot: window.autopilot }) })
    const hubspot = useScript({"key":"hubspot","type":"text/javascript","id":"hs-script-loader","async":true,"defer":true,"src":"//js.hs-scripts.com/43699019.js","tagPosition":"bodyClose"}, { use: () => ({ hubspot: window.hubspot }) })
    return { provide: { $scripts: { autopilot, hubspot, googleAnalytics } } }
  }
})